<template>
  <div class="calendar">
    <InputCalendarDatePicker
      v-model="model.date.selectedDateRange"
      v-model:pending-date="model.date.pendingDate"
      v-model:number-of-clicks="numberOfClicks"
    />

    <InputCalendarAdditional v-model="model.additional" />

    <InputCalendarFooter @apply="handleApply" />
  </div>
</template>

<script lang="ts" setup>
import type { DateFilter } from '../../Pageheader/Search/models';

const numberOfClicks = ref(0);

const model = defineModel<DateFilter>({
  default: {
    date: {
      selectedDateRange: [],
      pendingDate: '',
    },
    additional: {
      categories: [],
      dayTime: [],
    },
  } satisfies DateFilter,
});

const emit = defineEmits(['apply']);

/* introduced this check to make selection of single date work for all cases - also if there is a already a range selected */
const handleApply = () => {
  if (numberOfClicks.value % 2 === 1) {
    model.value.date.selectedDateRange = [];
  }
  emit('apply');
};
</script>

<style src="./Calendar.scss" scoped lang="scss"></style>
